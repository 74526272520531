<template>
  <div class="expand-item">
    <a-row>
      <a-col :span="12">
        <p>基本技能：{{ row.baseSkill }}</p>
      </a-col>
      <a-col :span="12">
        <p>
          {{ row.type == 1 ? '专业技能' : '从业资格证' }}：{{
            row.type == 1 ? row.professionalSkill : row.qualificationCertificate == 1 ? '安全员' : '建造师'
          }}
        </p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p>项目经历：{{ workObj[row.experience] }}</p>
      </a-col>
      <a-col :span="12">
        <p>技能等级证：{{ levelObj[row.skillLevelCertificate] }}</p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p>招聘人数：{{ row.number }}</p>
      </a-col>
      <a-col :span="12">
        <p>薪资标准：{{ row.salary }}</p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p>食宿：{{ roomBoard[row.boardLodging] }}</p>
      </a-col>
      <a-col :span="12">
        <p>路费：{{ toll[row.toll] }}</p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p>工作地点：{{ row.place }}</p>
      </a-col>
      <a-col :span="12">
        <p>工作时间：{{ formatTime(row.workStartTime) }}/{{ formatTime(row.workEndTime) }}</p>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <p>岗位职责：{{ row.duty }}</p>
      </a-col>
      <a-col :span="12">
        <p>职位描述：{{ row.jobDescription }}</p>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ExpandItem',
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      workObj: ['不限', '30万以下', '30-60万', '100万及以上'],
      levelObj: {
        0: '不限',
        1: '初级',
        2: '中级',
        3: '高级',
    '-1': '特殊工种证书'

      },
      roomBoard: ['包吃', '包住', '包吃住', '不提供'],
      toll: ['单程', '双程', '不提供', '来程算工资']
    }
  },
  methods: {
    formatTime (date) {
      return date && moment(date).format('YYYY-MM-DD')
    }
  }
}
</script>
