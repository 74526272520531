<template>
  <div class="workbenchModle__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      模板管理
    </div>
    <div class="body__wrapper">
      <div class="msg left">
        <div class="msg_title">
          <div class="msg_titleline"></div>
          用工模版
        </div>
        <div class="msg_search">
          <a-row type="flex" justify="end">
            <a-col :span="10">
              <a-input-search
                placeholder="请输入模板名称"
                v-model="leftSearch"
                @search="handleSearch('left')"
                @keydown.up="handleSearch('left')"
                allowClear
                enter-button="搜索"
                v-perms="'search-left'"
              />
            </a-col>
          </a-row>
        </div>
        <div class="modle_table">
          <p-table
            row-key="templateId"
            :columns="employmentColumns"
            :source-data="handleGetLeftTable"
            ref="leftTable"
            :scorll="{ y: 400 }"
            extraHeight="420"
          >
            <template slot="operation" slot-scope="text, record">
              <a-button type="link" @click="handleDetails(record, 'left')" v-perms="'details-left'">详情</a-button>
              <a-button type="link" @click="handleDelete(record, 'left')" v-perms="'delete-left'">删除</a-button>
            </template>
          </p-table>
        </div>
      </div>
      <div class="msg right">
        <div class="msg_title">
          <div class="msg_titleline"></div>
          物料模版
        </div>
        <div class="msg_search">
          <a-row type="flex" justify="end">
            <a-col :span="10">
              <a-input-search
                placeholder="请输入模板名称"
                v-model="rightSearch"
                @search="handleSearch('right')"
                @keydown.up="handleSearch('right')"
                allowClear
                enter-button="搜索"
                v-perms="'search-right'"
              />
            </a-col>
          </a-row>
        </div>
        <div class="modle_table">
          <p-table
            row-key="templateId"
            :columns="materialsColumns"
            :source-data="handleGetRightTable"
            ref="rightTable"
            :scorll="{ y: 400 }"
            extraHeight="420"
          >
            <template slot="operation" slot-scope="text, record">
              <a-button type="link" @click="handleDetails(record, 'right')" v-perms="'details-right'">详情</a-button>
              <a-button type="link" @click="handleDelete(record, 'right')" v-perms="'delete-right'">删除</a-button>
            </template>
          </p-table>
        </div>
      </div>
    </div>
    <a-modal
      :title="modalTitle"
      :visible="leftVisible"
      width="1000px"
      cancelText="取消"
      okText="确定"
      @ok="handleCancel"
      @cancel="handleCancel"
      :footer="null"
    >
      <a-table
        :scroll="{ y: 400 }"
        extraHeight="700"
        row-key="templateId"
        :columns="columns1"
        :pagination="false"
        :data-source="leftModalData"
      >
        <template slot="expandedRowRender" slot-scope="record">
          <expandItem :row="record" />
        </template>
      </a-table>
      <div class="model_table"></div>
      <!-- <div class="project_modle">
        <a-row>
          <a-col :span="12">
            <div class="recruitUse_text" v-for="(item,index) in userType" :key="index">人员类别：{{ item }}</div>
          </a-col>
          <a-col :span="12">
            <div class="recruitUse_text" v-for="(item,index) in positionName" :key="index">职位：{{ item }}</div>
          </a-col>
        </a-row>
      </div>-->
    </a-modal>
    <a-modal
      :title="modalTitle"
      :visible="rightVisible"
      width="1000px"
      cancelText="取消"
      okText="确定"
      @ok="handleCancel"
      @cancel="handleCancel"
      :footer="null"
    >
      <p-table
        :scroll="{ y: 400 }"
        extraHeight="700"
        :columns="columns"
        ref="modalTable"
        :source-data="getDataApi"
      ></p-table>
      <div class="model_table"></div>
    </a-modal>
  </div>
</template>

<script>
import {
  deleteTemplateDemand,
  deleteTemplateMaterial,
  DetailTemplateMaterial,
  queryTemplateDemandByPaging,
  queryTemplateMaterialByPaging
} from '@/api/workbench'
import { getTemplateDetails } from '@/api/material'
import { enterprisePublishDemand1, employmentEmploypersonnelType } from '@/api/workDemand'
import expandItem from './expand-item.vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (t, r, i) => i + 1
  },
  {
    title: '商品名称',
    dataIndex: 'commodityName',
    key: 'commodityName'
  },
  {
    title: '推荐规格型号',
    dataIndex: 'specifications',
    key: 'specifications'
  }
]
const columns1 = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (t, r, i) => i + 1,
    width: 60
  },
  {
    title: '人员类别',
    dataIndex: 'type',
    key: 'type',
    customRender: (text) => (text == 1 ? '技能人员' : '技术人员')
  },
  {
    title: '工种/专业',
    dataIndex: 'positionName',
    key: 'positionName'
  }
]

const employmentColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    customRender: (text, record, index) => index + 1
  },
  {
    title: '模版名称',
    dataIndex: 'templateName',
    key: 'templateName',
    ellipsis: true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    ellipsis: true,
    width: 200
  },

  {
    title: '操作',
    dataIndex: 'operation',
    width: 200,
    scopedSlots: { customRender: 'operation' }
  }
]
const materialsColumns = [
  {
    title: '序号',
    key: 'index',
    customRender: (text, record, index) => index + 1
  },
  {
    title: '模版名称',
    dataIndex: 'templateName',
    key: 'templateName',
    ellipsis: true
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    ellipsis: true,
    width: 200
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 200,
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'Index',
  components: {
    expandItem
  },
  data () {
    return {
      detailVisible: false,
      employmentColumns,
      materialsColumns,
      columns,
      columns1,
      leftSearch: '',
      rightSearch: '',
      form: {},
      modelVisible: false,
      edit: false,
      leftVisible: false,
      rightVisible: false,
      modalTitle: '',
      userTypeList: [],
      userType: '',
      positionName: '',
      templateId: '',
      leftModalData: []
    }
  },
  computed: {
    title () {
      return this.edit ? '编辑模板' : '新增模板'
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      employmentEmploypersonnelType().then((res) => {
        console.log(res)
        this.userTypeList = res.data
      })
    },
    handleGetLeftTable (params) {
      return queryTemplateDemandByPaging({
        ...params,
        templateName: this.leftSearch
      })
    },
    handleGetRightTable (params) {
      return queryTemplateMaterialByPaging({
        ...params,
        templateName: this.rightSearch
      })
    },
    handleDetails (row, type) {
      this.templateId = row.templateId
      this[`${type}Visible`] = true
      this.modalTitle = row.name || row.templateName
      // const fun = type === 'left' ? enterprisePublishDemand : DetailTemplateMaterial
      if (type === 'left') {
        console.log(1111111)
        enterprisePublishDemand1({
          templateName: row.templateName
        }).then((res) => {
          this.leftModalData = res.data
        })
      } else {
        this.$nextTick(() => {
          this.$refs.modalTable.initPage()
        })
      }
      // fun(row.id)
      //   .then(res => {
      //     // res.data.updateTime = dateFormat(res.data.updateTime)
      //     // this.form = res.data
      //     console.log(res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
    },
    handleEdit (row, type) {
      this.modelVisible = true
    },
    handleDelete (row, type) {
      this.$confirm({
        title: '删除提示',
        content: '确认删除此条数据吗？',
        onOk: () => {
          const fn = type === 'left' ? deleteTemplateDemand : deleteTemplateMaterial
          fn(row.templateId).then(() => {
            this.$message.success('模板删除成功')
            this.$refs[`${type}Table`].refresh()
          })
        }
      })
    },
    handleSearch (type) {
      this.$refs[`${type}Table`].initPage()
    },
    handleSubmit () {
      if (this.edit) {
      } else {
        this.handleCancel()
      }
    },
    getDataApi (params) {
      if (this.templateId) {
        return getTemplateDetails({
          ...params,
          templateId: this.templateId
        })
      }
      return Promise.resolve({})
    },
    getDataApi1 (params) {
      if (this.templateId) {
        const templateName = this.$refs.leftTable.tableData.find(
          (item) => item.templateId === this.templateId
        )?.templateName
        return enterprisePublishDemand1({
          templateName
        })
      }
      return Promise.resolve({})
    },
    handleCancel () {
      this.edit = false
      this.form = {}
      this.leftVisible = false
      this.rightVisible = false
      this.positionName = ''
      this.userType = ''
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('../../learnMsg/msg/index');
.workbenchModle__wrapper {
  height: 100%;
  .body__wrapper {
    height: calc(100% - 45px);
    display: flex;
  }
  .left,
  .right {
    flex: 1;
    padding: 24px;
    background: #ffffff;
  }
  .left {
    margin-right: 24px;
  }
  .msg_search {
    margin: 20px 0px;
  }
  .msg_title {
    margin: 0px;
  }
}
.model_table {
  height: 40px;
}
</style>
